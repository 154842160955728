const SECTIONS = [
  {
    id: 1,
    title: 'Велопрогулки',
    link: "https://t.me/veloprogulka39",
    image: "veloprogulka.jpg",
    slug: "veloprogulka",
    description: 'Воскресные велопрогулки - единственные велосипедные прогулки для начинающих велосипедистов. Идея мероприятия в том, чтобы учить новичков ездить в условиях городской инфраструктуры, чтобы они самостоятельно и аккуратно ездили. Велосипед - не гоночное средство, но один из видов городского транспорта.',
    label: 'Чат в Telegram'
  },
  {
    id: 2,
    title: 'Суздальский парк',
    link: "https://vk.com/suzles",
    image: "suzpark.jpg",
    slug: "suzpark",
    description : 'Сообщество неравнодушных к своему городу людей, которые делают большое дело для своего города: защищают зеленые территории города, одной из которых является Суздальский парк.'
  },
  {
    id: 3,
    title: 'Велосреда',
    link: "https://vk.com/velosreda39",
    image: "velosreda.jpg",
    slug: "velosreda",
    description: 'Велосреда — калининградское сообщество любителей покататься на велосипедах за городом (6+ с провожатыми, 13+ — без). В 19:30 от фонтана у памятника Шиллеру и до полуночи. Мы ездим каждую среду без исключений!'
  },
];

const STORE_BADGE_ITEMS = [
  { platform: 'ios', width: 240 },
  { platform: 'android', width: 240 },
];

export {
  SECTIONS,
  STORE_BADGE_ITEMS
};
